import { COMMANDS, feedAndCut, reset, setup } from "./commands.js";

import { encode } from "./encoder.js";

const alignments = ["left", "center", "right"] as const;
type Alignment = (typeof alignments)[number];
type Parts = Text[];
type Size = "normal" | "l" | "xl";
type Line = {
  size: Size;
  content: Element;
};

const BASE_LINE_WIDTH = 56;
const noCommand = new Uint8Array();

const getSizeCommand = (size: Size): Uint8Array => {
  switch (size) {
    case "normal":
      return COMMANDS.FONT_SIZE_NORMAL;
    case "l":
      return COMMANDS.FONT_SIZE_L;
    case "xl":
      return COMMANDS.FONT_SIZE_XL;
  }
};

const getLineWidth = (size: Size): number => {
  switch (size) {
    case "l":
      return BASE_LINE_WIDTH * (1 / 2);
    case "xl":
      return BASE_LINE_WIDTH * (1 / 4);
    case "normal":
    default:
      return BASE_LINE_WIDTH;
  }
};

class Text {
  static Tab(element: Element) {
    element.textContent = "  ";
    return Text.FromElement(element);
  }

  static Padding(count: number, alignment: Alignment, size: Size) {
    const text = Array(count).fill(" ").join("");
    return new Text(text, alignment, false, 0, size);
  }

  static FromElement(element: Element, size = "normal" as Size) {
    const alignment = (element.getAttribute?.("alignment") ??
      "left") as Alignment;
    const pad = Number(element.getAttribute?.("pad") ?? 0);
    const content = element.textContent ?? "";
    const text = content.padEnd(pad, " ");
    const bold = !!element.getAttribute("bold");

    return new Text(text, alignment, bold, pad, size);
  }

  constructor(
    public text: string,
    public alignment: Alignment,
    public bold: boolean,
    public pad: number,
    public size: Size
  ) {}

  isAligned(alignment: Alignment) {
    return this.alignment === alignment;
  }

  getCommand() {
    const boldCommand = this.bold ? COMMANDS.BOLD_SET : noCommand;
    const sizeCommand = getSizeCommand(this.size);
    return boldCommand.concat(sizeCommand.concat(encode(this.text)));
  }
}

const getMain = (xml: string) => {
  const root = new DOMParser().parseFromString(xml, "text/xml");

  if (root.documentElement.nodeName === "parsererror") {
    throw "Error while parsing XML template.";
  }

  if (!root?.firstElementChild) {
    throw "Error no <main> found";
  }
  return root.firstElementChild;
};

export const consolePrint = (xml: string) => {
  const main = getMain(xml);

  const receipt = Object.entries(main.children).reduce((text, childNode) => {
    const [, node] = childNode;
    switch (node.nodeName) {
      case "line": {
        const nodes = Object.entries(node.children).map(([_, node]) => node);
        const parts = getLineParts(
          "normal",
          nodes.filter(
            (node) => node.nodeName === "text" || node.nodeName === "tab"
          )
        );
        const allText = parts.map((part) => part.text).join("");
        return text.concat("\n").concat(allText + `(${allText.length})`);
      }
      case "br":
        return text.concat("\n");
      case "drawline":
        return text
          .concat("\n")
          .concat(Array(BASE_LINE_WIDTH).fill("-").join(""));
    }
    return text;
  }, "");

  console.log(receipt);
};

export const parse = (xml: string) => {
  const main = getMain(xml);

  return Object.entries(main.children)
    .map(([_, node]) => {
      switch (node.nodeName) {
        case "line": {
          const size = (node.getAttribute("size") ?? "normal") as Size;
          return parseLine({ content: node, size });
        }
        case "br":
          return COMMANDS.NEW_LINE;
        case "drawline":
          return COMMANDS.DRAW_LINE;
      }
      return noCommand;
    })
    .reduce((command, next) => command.concat(next), setup)
    .concat(feedAndCut);
};

const padToMaxLength = (size: Size, parts: Parts) => {
  const lineWidth = getLineWidth(size);

  const left = parts
    .filter((part) => part.isAligned("left"))
    .map((part) => part.text)
    .join("");
  const leftLen = left.length;

  const center = parts
    .filter((part) => part.isAligned("center"))
    .map((part) => part.text)
    .join("");
  const centerLen = center.length;

  const right = parts
    .filter((part) => part.isAligned("right"))
    .map((part) => part.text)
    .join("");
  const rightLen = right.length;

  const centerStart = lineWidth / 2 - Math.floor(centerLen / 2);
  const centerEnd = lineWidth / 2 + Math.floor(centerLen / 2);

  const padLeft = centerStart - leftLen - (centerLen % 2 == 0 ? 0 : 1);
  const padRight = lineWidth - centerEnd - rightLen;

  const firstCenter = Text.Padding(Math.max(padLeft, 0), "center", size);
  const lastCenter = Text.Padding(
    Math.max(padRight + Math.min(0, padLeft), 0),
    "center",
    size
  );

  parts = [
    ...parts.filter((p) => p.isAligned("left")),
    firstCenter,
    ...parts.filter((p) => p.isAligned("center")),
    lastCenter,
    ...parts.filter((p) => p.isAligned("right")),
  ];

  return parts;
};

const getLineParts = (size: Size, nodes: Element[]) => {
  const parts = nodes.reduce((part, node) => {
    switch (node.nodeName) {
      case "text": {
        const element = node as Element;

        const text = Text.FromElement(element, size);
        part.push(text);
        break;
      }
      case "tab": {
        const element = node as Element;

        part.push(Text.Tab(element));
        break;
      }
    }
    return part;
  }, [] as Parts);

  return padToMaxLength(size, parts);
};

const parseLine = (line: Line) => {
  const nodes = Object.entries(line.content.children).map(([_, node]) => node);

  const parts = getLineParts(
    line.size,
    nodes.filter((node) => node.nodeName === "text" || node.nodeName === "tab")
  );

  return parts
    .reduce(
      (command, part) => command.concat(part.getCommand()).concat(reset),
      new Uint8Array()
    )
    .concat(COMMANDS.NEW_LINE);
};
