import { AnimatePresence } from "framer-motion";

import { KitchenMenuDialog } from "./KitchenMenuDialog";
import { KitchenProductSelectorDialog } from "./KitchenProductSelectorDialog";
import { FadeIn, SlideUp } from "../Animations";
import { useConfig } from "../ConfigProvider";
import { SpinnerOverlay } from "../Spinner";
import { useRootState } from "../state/StateProvider";
import { useDelayedCondition } from "../useDelayedCondition";

export const Kitchen = () => {
    const { state, dispatch } = useRootState("kitchen");
    const { accessibilityMode } = state;

    const isLoading = state.subState.kind === "loading";
    const displayLoadingIndicator = useDelayedCondition(isLoading, 2000);

    const { kitchen } = useConfig();

    return (
        <AnimatePresence>
            (
            <div data-theme="kitchen">
                <FadeIn className="pt-30 fixed inset-0 bg-primary-900 bg-opacity-75" />
                <SlideUp
                    key="select"
                    className={`fixed inset-x-0 bottom-0 ${
                        accessibilityMode ? "top-80" : "top-12"
                    } z-10 rounded-t-xl`}
                >
                    {displayLoadingIndicator && <SpinnerOverlay />}
                    {state.subState.kind !== "productSelector" && (
                        <KitchenMenuDialog
                            cartQuantity={state.cart.lineItems.length}
                            kitchenMenu={state.menu}
                            onCancel={() =>
                                dispatch({ kind: "hideKitchenMenu" })
                            }
                        />
                    )}
                    {state.subState.kind === "productSelector" && (
                        <KitchenProductSelectorDialog
                            product={state.subState.product}
                            upsellingProducts={
                                kitchen.upsellingConfiguration?.[
                                    state.subState.product.id
                                ] ?? []
                            }
                            onConfirm={(
                                productId,
                                barcode,
                                quantity,
                                addOnOptionIds,
                                upsellingProductBarcodes
                            ) => {
                                dispatch({
                                    kind: "addKitchenProduct",
                                    productId: productId,
                                    barcode: barcode,
                                    quantity,
                                    addOnOptionIds,
                                    upsellingProductBarcodes
                                });
                            }}
                            onCancel={() =>
                                dispatch({ kind: "hideKitchenProductSelector" })
                            }
                        />
                    )}
                </SlideUp>
            </div>
            )
        </AnimatePresence>
    );
};
