import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import {
    KitchenProduct,
    KitchenProductAddOn,
    KitchenProductAddOnOption
} from "../../types/KitchenMenu";
import * as Price from "../../utils/Price";
import { translate } from "../../utils/translations";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import * as svg from "../svg";

type ProductStepProps = {
    product: KitchenProduct;
    selectedOptionIds: Set<number>;
    setSelectedOptionIds: Dispatch<SetStateAction<Set<number>>>;
};

const ProductStep = ({
    product,
    selectedOptionIds,
    setSelectedOptionIds
}: ProductStepProps) => {
    const { t, i18n } = useTranslation();

    const [isProductInformationOpen, setIsProductInformationOpen] =
        useState(false);

    const ProductAddOnOption = ({
        id,
        label,
        name,
        type,
        checked,
        onChange
    }: {
        id: number;
        label: string;
        name?: string;
        type: string;
        checked: boolean;
        onChange: ChangeEventHandler<HTMLInputElement> | undefined;
    }) => {
        return (
            <div className="flex items-center rounded-lg border-2 border-primary-200 bg-white p-3">
                <input
                    className="mr-2 h-5 w-5 accent-primary-500"
                    id={`option-${id}`}
                    type={type}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />
                <label
                    className="grow text-start text-primary-600"
                    htmlFor={`option-${id}`}
                >
                    {label}
                </label>
            </div>
        );
    };

    const CheckboxGroup = ({
        options
    }: {
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        type="checkbox"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptions = new Set(optionIds);

                                if (optionIds.has(option.id)) {
                                    updatedOptions.delete(option.id);
                                } else {
                                    updatedOptions.add(option.id);
                                }

                                return updatedOptions;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const RadioGroup = ({
        id,
        options
    }: {
        id: number;
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div className="grid grid-cols-2 gap-2">
                {options.map((option) => (
                    <ProductAddOnOption
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        name={`${"add-on-" + id}`}
                        type="radio"
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptionIds = new Set(optionIds);

                                options.forEach((addOnOption) =>
                                    updatedOptionIds.delete(addOnOption.id)
                                );
                                updatedOptionIds.add(option.id);

                                return updatedOptionIds;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const ProductAddOn = ({ addOn }: { addOn: KitchenProductAddOn }) => {
        return (
            <div className="flex flex-col gap-2 text-center">
                <div
                    className={`
                     text-lg text-primary-500
                    ${
                        addOn.required &&
                        "after:ml-0.5 after:text-red-500 after:content-['*']"
                    }`}
                >
                    {translate(addOn.description, i18n)}
                </div>

                {addOn.multipleChoice ? (
                    <CheckboxGroup options={addOn.options} />
                ) : (
                    <RadioGroup id={addOn.id} options={addOn.options} />
                )}
            </div>
        );
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto rounded-2xl bg-primary-100 p-3">
                {product.addOns.map((productAddOn) => (
                    <ProductAddOn addOn={productAddOn} key={productAddOn.id} />
                ))}
            </div>

            {isProductInformationOpen && (
                <Dialog
                    className="bg-primary-500 text-center"
                    title={t("kitchenMenu.productInformation")}
                    theme="kitchen"
                    onBackgroundClick={() => setIsProductInformationOpen(false)}
                    primaryButton={{
                        title: t("done"),
                        action: () => setIsProductInformationOpen(false)
                    }}
                >
                    <div className="text-lg">
                        {t("kitchenMenu.nutritionalInformation")}
                    </div>
                    <div className="text-sm">
                        {translate(product.nutritionalInformation, i18n)}
                    </div>
                    <div className="text-lg">{t("kitchenMenu.allergens")}</div>
                    <div className="text-sm">
                        {translate(product.allergens, i18n)}
                    </div>
                </Dialog>
            )}
            <Button
                onClick={() => setIsProductInformationOpen(true)}
                label={t("kitchenMenu.productInformation")}
                className="w-full text-center opacity-70"
            />
        </>
    );
};

type UpsellingStepProps = {
    upsellingProducts: KitchenProduct[];
    selectedUpsellingProductBarcodes: Set<string>;
    setSelectedUpsellingProductBarcodes: Dispatch<SetStateAction<Set<string>>>;
};

const UpsellingStep = ({
    selectedUpsellingProductBarcodes,
    setSelectedUpsellingProductBarcodes,
    upsellingProducts
}: UpsellingStepProps) => {
    const { i18n, t } = useTranslation();

    return (
        <div className="flex h-full flex-1 flex-col gap-2 overflow-y-auto rounded-2xl bg-primary-100 p-3 text-center">
            <span className="text-lg text-primary-500">
                {t("kitchenMenu.upsellingTitle")}
            </span>
            {upsellingProducts.map((product) => (
                <div key={product.id}>
                    <button
                        className="grid w-full grid-cols-10 items-center justify-between gap-2 rounded-lg border-2 border-primary-200 bg-white p-3 disabled:opacity-50"
                        disabled={!product.available}
                        onClick={() =>
                            setSelectedUpsellingProductBarcodes((barcodes) => {
                                const updated = new Set(barcodes);

                                if (barcodes.has(product.barcode)) {
                                    updated.delete(product.barcode);
                                } else {
                                    updated.add(product.barcode);
                                }

                                return updated;
                            })
                        }
                    >
                        <div className="col-span-7 flex items-center gap-2 text-left">
                            <input
                                className="h-5 w-5"
                                readOnly
                                type="checkbox"
                                checked={selectedUpsellingProductBarcodes.has(
                                    product.barcode
                                )}
                            />
                            {translate(product.name, i18n)}
                        </div>
                        <img className="max-h-6" src={product.imageUrl} />
                        <div className="col-span-2">
                            {product.price
                                ? ` ${Price.format(product.price)}`
                                : "-"}
                        </div>
                    </button>
                </div>
            ))}
        </div>
    );
};

type KitchenProductSelectorDialogProps = {
    product: KitchenProduct;
    upsellingProducts: KitchenProduct[];
    onConfirm: (
        productId: number,
        barcode: string,
        quantity: number,
        addOnOptionIds: number[],
        upsellingProductIds: string[]
    ) => void;
    onCancel: () => void;
};

export const KitchenProductSelectorDialog = ({
    product,
    upsellingProducts,
    onConfirm,
    onCancel
}: KitchenProductSelectorDialogProps) => {
    const { t, i18n } = useTranslation();
    const [selectedOptionIds, setSelectedOptionIds] = useState(
        () => new Set<number>()
    );
    const [
        selectedUpsellingProductBarcodes,
        setSelectedUpsellingProductBarcodes
    ] = useState(() => new Set<string>());

    const [step, setStep] = useState<"product" | "upselling">("product");

    const currentTotal = (() => {
        if (!product.price) {
            return undefined;
        }

        const selectedUpsellingProductsPrice = upsellingProducts
            .filter((p) => selectedUpsellingProductBarcodes.has(p.barcode))
            .reduce((acc, cur) => acc + Number(cur.price?.value || 0), 0);

        return {
            value: `${
                selectedUpsellingProductsPrice + Number(product.price.value) ||
                0
            }`,
            currency: product.price.currency
        };
    })();

    return (
        <div className="z-10 flex h-full flex-col gap-2 bg-white p-3">
            <div className="flex items-center justify-between">
                <button
                    className="press-effect rounded bg-primary-200 p-1 text-primary-500"
                    onClick={() => {
                        if (step === "upselling") {
                            return setStep("product");
                        }

                        onCancel();
                    }}
                >
                    {svg.leftArrow}
                </button>
                <div className="flex flex-col items-center text-center text-primary-500">
                    <img
                        src={product.imageUrl ?? placeholderImage}
                        className="h-20"
                    />
                    <span className="w-full overflow-hidden text-ellipsis text-xl font-semibold capitalize">
                        {translate(product.name, i18n).toLowerCase()}
                    </span>
                </div>
                <div className="h-8 w-8" />
            </div>

            {step === "product" ? (
                <ProductStep
                    product={product}
                    setSelectedOptionIds={setSelectedOptionIds}
                    selectedOptionIds={selectedOptionIds}
                />
            ) : (
                <UpsellingStep
                    upsellingProducts={upsellingProducts}
                    setSelectedUpsellingProductBarcodes={
                        setSelectedUpsellingProductBarcodes
                    }
                    selectedUpsellingProductBarcodes={
                        selectedUpsellingProductBarcodes
                    }
                />
            )}

            <button
                className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white disabled:opacity-30"
                disabled={
                    !product.addOns
                        .filter((productAddOn) => productAddOn.required)
                        .every((productAddOn) =>
                            productAddOn.options.some((option) =>
                                selectedOptionIds.has(option.id)
                            )
                        )
                }
                onClick={() => {
                    if (upsellingProducts.length > 0 && step === "product") {
                        return setStep("upselling");
                    }

                    onConfirm(
                        product.id,
                        product.barcode,
                        1,
                        [...selectedOptionIds],
                        [...selectedUpsellingProductBarcodes]
                    );
                }}
            >
                {step === "product" ? t("continue") : t("done")}
                {currentTotal ? ` (${Price.format(currentTotal)})` : ""}
            </button>
        </div>
    );
};
